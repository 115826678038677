export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const courses = [
  {
    _id: "63bea63889c2030476a6b9c9",
    product_name: "B.Tech",
    product_pricing: "11999",
    discount_pricing: "7999",
    total_session: 6,
    activate: true,
    sessionIncludes: [
      "Introducation",
      "Tech Round 1",
      "HR Round 2",
      "Tech Round 2",
      "Tech Round 3",
      "HR Round 1",
    ],
    __v: 2,
  },
  // {
  //   _id: "63bea6c389c2030476a6b9ca",
  //   product_name: "MBA",
  //   product_pricing: "14999",
  //   discount_pricing: "11999",
  //   total_session: 6,
  //   activate: false,
  //   sessionIncludes: [
  //     "Introducation",
  //     "HR Round 1",
  //     "HR Round 2",
  //     "Tech Round 2",
  //     "Tech Round 1",
  //     "Tech Round 3",
  //   ],
  // },
  {
    _id: "63bea6d889c2030476a6b9cb",
    product_name: "B.Com",
    product_pricing: "4999",
    discount_pricing: "1999",
    total_session: 1,
    activate: true,
    sessionIncludes: ["HR Round 1", "Introducation"],
  },
  {
    _id: "65cf42edfe6ffab67d908066",
    product_name: "Digital Marketing",
    product_pricing: "5000",
    discount_pricing: "3249",
    total_session: 3,
    activate: true,
    sessionIncludes: ["Introducation", "HR Round 1", "Tech Round 1"],
  },
];

export const educationStreams = [
  "Science",
  "Technology",
  "Engineering",
  "Mathematics",
  "Arts",
  "Humanities",
  "Social Sciences",
  "Business",
  "Medicine",
  "Law",
  "Environmental Studies",
  "Psychology",
  "Education",
  "Communication",
  "Information Technology",
  "Culinary Arts",
  "Design",
  "Nursing",
  "Dentistry",
  "Pharmacy",
  "Agriculture",
  "Architecture",
  "Economics",
  "Music",
  "Theater",
  "Film Production",
  "Political Science",
  "Astronomy",
  "Geology",
  "Linguistics",
  "Anthropology",
  "History",
  "Chemistry",
  "Physics",
  "Sociology",
  "Philosophy",
  "Religious Studies",
  "Environmental Engineering",
  "Biotechnology",
  "Finance",
  "International Relations",
  "Media Studies",
  "Criminal Justice",
  "Geography",
  "Public Health",
  "Urban Planning",
  "Fashion Design",
  "Interior Design",
  "Mathematical Sciences",
  "Data Science",
  "Biomedical Engineering",
  "Artificial Intelligence",
  "Robotics",
  "Aviation",
  "Marine Biology",
  "Game Design",
  "Astronomy",
  "Cybersecurity",
  "Environmental Science",
  "Sustainable Energy",
  "Gender Studies",
  "Neuroscience",
  "Veterinary Medicine",
  "Digital Marketing",
  "Ethnomusicology",
];

export const feedbackTitle = [
  "Education Background",
  "Communication Skills",
  "Listening Skills",
  "Interpersonal Skills",
  "Leadership Ability",
  "Enthusiasm Level",
  "Eagerness Of Improvement",
  "Interest of Taking Initiatives",
  "Time Management",
  "Technical Knowlegde",
  "Ethusiasm on Learning New Skills",
  "Stress Handling",
  "Current Industry Trend awareness",
  "Additional Certification",
  "Summar Internship/Live projects",
  "Tech Skill Initiative",
  "Subject Understanding",
  "Overall Hirability Index",
  "Comment On Technical Readiness",
  "Comment On Communication Skills",
];

export const SessionType = [
  "Introducation",
  "HR Round 1",
  "Tech Round 1",
  "HR Round 2",
  "Tech Round 2",
  "Tech Round 3",
];

export const Faq = [
  {
    question: "What is Skillkart, and what services do you offer?",
    answer:
      "Skillart is a platform dedicated to fostering professional growth and career advancement. We offer mentorship services provided by experienced industry professionals. Our mentors offer guidance, advice, and support to help individuals navigate their careers more effectively",
  },
  {
    question: "What is the mechanism underlying mentorship at Skillkart?",
    answer:
      "Mentorship at Skillkart unfolds as a meticulously tailored journey. Individuals peruse through our repository of mentors, meticulously selecting a mentor whose aspirations harmonize with their own professional trajectory. Following this, they extend a mentorship entreaty. Once this synergy is established, mentors embark on a singular voyage with mentees, offering unwavering personalized counsel, dispensing profound insights, and steering mentees towards the realization of their vocational ambitions.",
  },
  {
    question:
      "Who are the mentors on Skillkart, and what industries do they cover?",
    answer:
      "At Skillkart, our mentors are distinguished experts boasting extensive proficiency across an array of industries. Their purview encompasses the realms of technology, finance, healthcare, marketing, and an array of other domains. The process of mentor selection is an assiduous one, predicated on the meticulous vetting of candidates, ensuring their capacity to furnish our users with invaluable perspectives and guidance.",
  },
  {
    question:
      "Is skillkart suitable for recent graduates and experienced professionals alike?",
    answer:
      "Yes, absolutely. Skillkart caters to individuals at all stages of their careers. Whether you're a recent graduate looking for guidance on entering your field or an experienced professional seeking to advance your career, our mentors can provide tailored support.",
  },
  {
    question: "How do I get started with mentorship on Skillkart?",
    answer:
      ": To begin your mentorship journey, simply create an account on our platform. Browse our mentor profiles, select a mentor, and request mentorship. Once you're matched with a mentor, you can start receiving personalized guidance.",
  },
  {
    question: "Can I change my mentor if I'm not satisfied with the match?",
    answer:
      "While we strive to make the best mentor-mentee matches, we understand that compatibility is essential. If you find that your mentorship is not meeting your expectations, you can contact our support team, and we'll assist you in exploring other mentor options",
  },
  {
    question: "Is my personal information safe and secure on Skillkart?",
    answer:
      "Rest assured, your privacy and the security of your data are paramount to us. We have implemented stringent security protocols to safeguard your personal information. For a more comprehensive understanding of our data protection measures, we encourage you to peruse our privacy policy, which elucidates the intricacies of how we fortify your data.",
  },
  {
    question:
      "Is skillkart suitable for recent graduates and experienced professionals alike?",
    answer:
      "Yes, absolutely. Skillkart caters to individuals at all stages of their careers. Whether you're a recent graduate looking for guidance on entering your field or an experienced professional seeking to advance your career, our mentors can provide tailored support.",
  },
];

export const MentorSkill = [
  // Programming Languages
  "JavaScript",
  "TypeScript",
  "CoffeeScript",
  "Python",
  "Java",
  "C++",
  "C#",
  "Ruby",
  "PHP",
  "Swift",
  "Kotlin",
  "Go",
  "Rust",
  "Dart",
  "R",
  "MATLAB",
  "Perl",
  "Scala",
  "Groovy",
  "Lua",
  "Haskell",
  "Julia",
  "Shell Scripting (e.g., Bash)",
  "SQL (Structured Query Language)",
  "PL/SQL (Procedural Language/SQL)",
  "Assembly Language (various architectures)",

  // Frontend Frameworks and Libraries
  "React.js",
  "Angular",
  "Vue.js",
  "jQuery",
  "Ember.js",
  "Backbone.js",
  "Preact",
  "Aurelia",
  "Polymer",
  "Svelte",
  "Cycle.js",
  "Mithril",
  "Alpine.js",
  "Stimulus",
  "Marko",
  "Inferno",
  "Riot.js",
  "Sapper",
  "Quasar Framework",
  "Choo",

  // Backend Languages and Frameworks
  "Node.js",
  "Express.js",
  "Nest.js",
  "Hapi.js",
  "Koa.js",
  "Meteor",
  "Adonis.js",
  "Sails.js",
  "Feathers.js",
  "Total.js",
  "Strapi",
  "LoopBack",
  "Fastify",
  "Next.js (for server-side rendering)",
  "Nuxt.js (for server-side rendering)",
  "Deno (JavaScript/TypeScript runtime)",
  "GraphQL (Query language for APIs)",
  "Socket.io (Real-time communication)",
  "Serverless Framework (for serverless computing)",
  "Electron (for building desktop applications)",

  // Databases
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "SQLite",
  "Oracle Database",
  "Microsoft SQL Server",
  "Redis",
  "Cassandra",
  "Couchbase",
  "DynamoDB",
  "Firebase Realtime Database",
  "Neo4j (Graph Database)",
  "CouchDB",
  "MariaDB",
  "Amazon Aurora",
  "Elasticsearch",
  "RethinkDB",
  "InfluxDB",
  "SQLite",
  "ArangoDB",
  "HBase",
  "Redshift",
  "Bigtable",
];

export const areasOfExpertise = [
  "Software Development",
  "Data Science and Machine Learning",
  "Cloud Computing and DevOps",
  "Cybersecurity",
  "Database Management",
  "Network Administration",
  "Web Development",
  "Mobile App Development",
  "AI and Natural Language Processing (NLP)",
  "IoT (Internet of Things)",
  "Strategic Planning",
  "Financial Management",
  "Marketing and Branding",
  "Sales and Business Development",
  "Project Management",
  "Human Resources Management",
  "Operations and Supply Chain Management",
  "Entrepreneurship",
  "Business Analytics",
  "Change Management",
  "Medical Diagnosis",
  "Surgery and Anesthesia",
  "Clinical Research",
  "Public Health",
  "Healthcare Administration",
  "Medical Imaging",
  "Biotechnology",
  "Pharmaceutical Research",
  "Epidemiology",
  "Healthcare Informatics",
  "Civil Engineering",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Aerospace Engineering",
  "Chemical Engineering",
  "Environmental Engineering",
  "Structural Engineering",
  "Materials Science",
  "Robotics",
  "Biomedical Engineering",
  "Physics",
  "Chemistry",
  "Biology",
  "Astronomy",
  "Geology",
  "Environmental Science",
  "Genetics",
  "Neuroscience",
  "Quantum Mechanics",
  "Music Composition and Performance",
  "Visual Arts and Design",
  "Film Production and Direction",
  "Acting and Theater",
  "Animation and Multimedia",
  "Graphic Design",
  "Photography",
  "Writing and Creative Writing",
  "Art History",
  "Game Design and Development",
  "Particle Physics",
  "Pedagogy and Curriculum Development",
  "Educational Technology (EdTech)",
  "Special Education",
  "Early Childhood Education",
  "Higher Education Administration",
  "Educational Psychology",
  "Online Learning and E-Learning",
  "Adult Education",
  "School Counseling",
  "Educational Research",
  "Investment Banking",
  "Financial Analysis",
  "Asset Management",
  "Corporate Finance",
  "Economics Research",
  "Risk Management",
  "Taxation",
  "Financial Planning",
  "Banking and Financial Services",
  "Real Estate Finance",
  "Criminal Law",
  "Civil Law",
  "Corporate Law",
  "Intellectual Property Law",
  "International Law",
  "Environmental Law",
  "Family Law",
  "Tax Law",
  "Human Rights Law",
  "Psychology",
  "Sociology",
  "Anthropology",
  "Political Science",
  "Economics",
  "Geography",
  "History",
  "Social Work",
  "Cultural Studies",
  "Linguistics",
  "Legal Research and Writing",
  "Full Stack Development",
  "Frontend Development",
  "Backend Development",
  "Mobile Development",
  "Web Development",
  "Game Development",
  "UI/UX Design",
  "Database Development",
  "DevOps",
  "Cloud Computing",
  "Embedded Systems",
  "API Development",
  "Software Architecture",
  "Quality Assurance (QA)",
  "Agile Development",
  "Scrum Methodology",
  "CI/CD (Continuous Integration/Continuous Deployment)",
  "Test-Driven Development (TDD)",
  "Responsive Web Design",
  "Cross-Platform Development",
  "Blockchain Development",
  "AR/VR Development",
  "Machine Learning Engineering",
  "Data Engineering",
  "Frontend Frameworks",
  "Backend Frameworks",
  "Version Control",
  "Containerization and Orchestration ",
  "Serverless Computing",
  "Microservices Architecture",
  "RESTful API Design",
  "GraphQL Development",
  "Database Management Systems",
  "Mobile App Frameworks",
  "Game Engines",
];
export const areasOfSpecilzation = [
  {
    category: "Technology and IT",
    expertise: [
      "Software Development",
      "Data Science and Machine Learning",
      "Cloud Computing and DevOps",
      "Cybersecurity",
      "Database Management",
      "Network Administration",
      "Web Development",
      "Mobile App Development",
      "AI and Natural Language Processing (NLP)",
      "IoT (Internet of Things)",
    ],
  },
  {
    category: "Business and Management",
    expertise: [
      "Strategic Planning",
      "Financial Management",
      "Marketing and Branding",
      "Sales and Business Development",
      "Project Management",
      "Human Resources Management",
      "Operations and Supply Chain Management",
      "Entrepreneurship",
      "Business Analytics",
      "Change Management",
    ],
  },
  {
    category: "Healthcare and Medicine",
    expertise: [
      "Medical Diagnosis",
      "Surgery and Anesthesia",
      "Clinical Research",
      "Public Health",
      "Healthcare Administration",
      "Medical Imaging",
      "Biotechnology",
      "Pharmaceutical Research",
      "Epidemiology",
      "Healthcare Informatics",
    ],
  },
  {
    category: "Engineering",
    expertise: [
      "Civil Engineering",
      "Mechanical Engineering",
      "Electrical Engineering",
      "Aerospace Engineering",
      "Chemical Engineering",
      "Environmental Engineering",
      "Structural Engineering",
      "Materials Science",
      "Robotics",
      "Biomedical Engineering",
    ],
  },
  {
    category: "Science",
    expertise: [
      "Physics",
      "Chemistry",
      "Biology",
      "Astronomy",
      "Geology",
      "Environmental Science",
      "Genetics",
      "Neuroscience",
      "Quantum Mechanics",
      "Particle Physics",
    ],
  },
  {
    category: "Arts and Entertainment",
    expertise: [
      "Music Composition and Performance",
      "Visual Arts and Design",
      "Film Production and Direction",
      "Acting and Theater",
      "Animation and Multimedia",
      "Graphic Design",
      "Photography",
      "Writing and Creative Writing",
      "Art History",
      "Game Design and Development",
    ],
  },
  {
    category: "Education",
    expertise: [
      "Pedagogy and Curriculum Development",
      "Educational Technology (EdTech)",
      "Special Education",
      "Early Childhood Education",
      "Higher Education Administration",
      "Educational Psychology",
      "Online Learning and E-Learning",
      "Adult Education",
      "School Counseling",
      "Educational Research",
    ],
  },
  {
    category: "Finance and Economics",
    expertise: [
      "Investment Banking",
      "Financial Analysis",
      "Asset Management",
      "Corporate Finance",
      "Economics Research",
      "Risk Management",
      "Taxation",
      "Financial Planning",
      "Banking and Financial Services",
      "Real Estate Finance",
    ],
  },
  {
    category: "Law and Legal Studies",
    expertise: [
      "Criminal Law",
      "Civil Law",
      "Corporate Law",
      "Intellectual Property Law",
      "International Law",
      "Environmental Law",
      "Family Law",
      "Tax Law",
      "Human Rights Law",
      "Legal Research and Writing",
    ],
  },
  {
    category: "Social Sciences",
    expertise: [
      "Psychology",
      "Sociology",
      "Anthropology",
      "Political Science",
      "Economics",
      "Geography",
      "History",
      "Social Work",
      "Cultural Studies",
      "Linguistics",
    ],
  },
  {
    category: "Development Expertise",
    expertise: [
      "Full Stack Development",
      "Frontend Development",
      "Backend Development",
      "Mobile Development",
      "Web Development",
      "Game Development",
      "UI/UX Design",
      "Database Development",
      "DevOps",
      "Cloud Computing",
      "Embedded Systems",
      "API Development",
      "Software Architecture",
      "Quality Assurance (QA)",
      "Agile Development",
      "Scrum Methodology",
      "CI/CD (Continuous Integration/Continuous Deployment)",
      "Test-Driven Development (TDD)",
      "Responsive Web Design",
      "Cross-Platform Development",
      "Blockchain Development",
      "AR/VR Development",
      "Machine Learning Engineering",
      "Data Engineering",
      "Frontend Frameworks",
      "Backend Frameworks",
      "Version Control",
      "Containerization and Orchestration ",
      "Serverless Computing",
      "Microservices Architecture",
      "RESTful API Design",
      "GraphQL Development",
      "Database Management Systems",
      "Mobile App Frameworks",
      "Game Engines",
    ],
  },
];
